<template>
  <div class="container">
    <div class="login_box">
      <div class="login_box--left">
        <img :src="loginLogo" alt="">
      </div>
      <div class="login_box--right">
        <el-form ref="form" class="login_form" :model="formData" :rules="formRules" hide-required-asterisk>
          <div class="form_title">
            <div class="title">{{ t('form.loginBtn') }}</div>
            <!-- <div class="sub_title">User Login</div>
              -->
            <lang-select />
          </div>
          <el-form-item prop="username">
            <el-input
              v-model="formData.username"
              :placeholder="t('form.inputPlaceholder')"
              prefix-icon="User" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :placeholder="t('form.inputPlaceholder')"
              @keyup.enter.native="onSubmit"
              v-model="formData.password"
              prefix-icon="Lock"
              show-password type="password" />
          </el-form-item>
          <div class="login_btn">
            <el-button type="primary" :loading="loading" @click="onSubmit">{{ t('form.loginBtn') }}</el-button>
          </div>
          <div class="user_other">
            <div class="other_plat">
              <div class="line"></div>
              <div class="text">{{ t('form.thirdPartyLogin') }}</div>
              <div class="line"></div>
            </div>
            <div class="img_list">
              <img class="plat_icon" @click="loginByDingTalk" src="@/assets/ding_icon.png" alt="">
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, computed } from 'vue'
import { LoginByDingTalk, userManagement, updateConfig } from '@/utils/api.js'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
const t = inject('t')
const form = ref()
const loading = ref(false)
const store = useStore()
const route = useRoute()
const formData = ref({
  username: '',
  password: ''
})
// 表单校验规则
const formRules = {
	username: [
    { required: true, message: t('form.emptyErrMsg') },
	  { min: 3, max: 30, message: t('form.usernameCheck') }
	],
	password: [
    { required: true, message: t('form.emptyErrMsg') },
	  { min: 3, max: 30, message: t('form.usernameCheck') }
  ]
}
// 左侧logo
const loginLogo = computed(() => {
  const language = localStorage.getItem('language').toLowerCase()
  return require(`@/assets/login_logo_${language}.png`)
})
onMounted(() => {
  if (route.query.authCode) { // 是否为第三方的路
    store.commit('CLEAR_ESCROW_ID') // 清除代管企业销售id
    userManagement.roleDetailByDingTalk(route.query.authCode).then(res => {
      ElMessage.success(t('form.success'))
      store.commit('SET_USER', res)
    }).catch(error => {
    })
  }
})

// 登录
const onSubmit = async () => {
  await form.value.validate(valid => {
    if (valid) {
      loading.value = true
      store.commit('CLEAR_ESCROW_ID') // 清除代管企业销售id
      store.dispatch('Login', formData.value).then(async res => {
        await updateConfig({ language: localStorage.getItem('language').toLowerCase() === 'zh-cn' ? 'zh' : 'en' })
        loading.value = false
      }).catch(error => {
        loading.value = false
      })
    }
  })
}

// 钉钉登录
const loginByDingTalk = () => {
  sessionStorage.removeItem("loginBy") // 清除登录方式
  store.commit('CLEAR_ESCROW_ID') // 清除代管企业销售id
  store.commit('CLEAR_USER') // 清除用户信息
  let protocol = window.location.protocol
  let hostname = window.location.host
  const origin = encodeURIComponent(`${protocol}//${hostname}/login`)
  sessionStorage.setItem("loginBy", true)
  LoginByDingTalk(origin).then(data => {
    location.href = data.jump_uri
  })
}
</script>

<style lang="scss">
@import './index.scss';
</style>